import React, { useEffect, useRef, useState } from 'react';
import { LoginApi } from '../../_helpers/service';
import Button from '../../_ui/Button/Button';
import Modal from '../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import './TokenRefresherTranslate'
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { handleError } from '../../utils/handleError';

let currentDiff = 600000;
let currentUserActive = false;
export default function TokenRefresher() {
  const { t } = useTranslation();
  const [diff, setDiff] = useState(600000);
  const [userActive, setUserActive] = useState(false);
  const [showBtnTimer, setShowBtnTimer] = useState(0);
  const [expiredAt, setExpiredAt] = useState(0);
  const interval = useRef();
  const expiredInterval = useRef();

  useEffect(() => {
    let expiredAtLocal = localStorage.getItem('expiredAt');
    if (expiredAtLocal) {
      startAction(expiredAtLocal)
    }
    return () => {
      window.removeEventListener('mousemove', moveListener);
      window.removeEventListener('keypress', moveListener);
      clearInterval(interval.current);
      clearInterval(expiredInterval.current);
    }
  }, [localStorage.getItem('refreshToken')])

  const startAction = (expiredAtLocal) => {
    if (expiredAtLocal && expiredAtLocal > Date.now() + 10000) {
      window.addEventListener('mousemove', moveListener);
      window.addEventListener('keypress', moveListener);
      let diffLocal = expiredAtLocal - Date.now();
      setDiff(diffLocal)
      setExpiredAt(expiredAtLocal)
      currentDiff = expiredAtLocal - Date.now();
      interval.current = setInterval(checkToken, 1000);
      expiredInterval.current = setInterval(checkExpiredAt, 5000);
    }
  }
  const moveListener = () => {
    if ((diff > 60000 || currentDiff > 60000) && !userActive) {
      setUserActive(true)
      currentUserActive = true
    }
  };

  const checkToken = async () => {
    if (diff <= 500 || currentDiff <= 500) {
      window.location.reload();
    } else if ((diff <= 60000 || currentDiff <= 60000) && (userActive || currentUserActive || window.test)) {
      await refreshToken();
    }
    if (showBtnTimer > 0) {
      setShowBtnTimer(showBtnTimer - 1)
    }
    if (diff > 500 || currentDiff > 500) {
      setDiff((prev) => {
        currentDiff = prev - 1000;
        return prev - 1000;
      });
    }
  };

  const checkExpiredAt = () => {
    let expiredAtLocal = localStorage.getItem('expiredAt');
    if (expiredAtLocal && expiredAtLocal !== expiredAt) {
      let diffLocal = expiredAtLocal - Date.now();
      setDiff(diffLocal);
      currentDiff = expiredAtLocal - Date.now();
      setExpiredAt(expiredAtLocal)
    }
  };
  async function refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    try {
      if (refreshToken) {
        const response = await LoginApi.refreshUser(refreshToken);
        if (response.data.status === 'SUCCESS') {
          const decode = jwtDecode(response.data.accessToken);
          if (decode.exp) {
            const expiredAtLocal = decode.exp * 1000 - 10000;
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            localStorage.setItem('expiredAt', expiredAtLocal);
            setDiff(expiredAtLocal - Date.now());
            currentDiff = expiredAtLocal - Date.now();
            setUserActive(false);
            currentUserActive = false;
            interval.current = setInterval(checkToken, 1000);
          }
        }
      }
    } catch (error) {
      handleError(error, t('refreshToken_error'));
    }
  }

  return (
    <>
      {
        (diff <= 40000 && !userActive) ?
          <Modal open title={t('tokenRefresher_title')}>
            <DialogContent>
              <div style={{ fontSize: '18px' }}>
                {t('tokenRefresher_expiredAt')} {Math.round(diff / 1000)}
                {t('tokenRefresher_second')}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={refreshToken} text={t('tokenRefresher_stay')} />
            </DialogActions>
          </Modal> : <></>
      }
    </>
  );
}
