import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import NotificationContainer from 'react-notifications-component';
import ProtectedRoute from './ProtectedRoute';
import { checkLogin, loginModule } from '../pages/LoginPage/LoginDucks';
import Page404 from '../pages/Page404/Page404';
import routerProps, { headerPaths, sidebarPaths } from '../_helpers/routerProps';
import Header from '../components/Header/Header';
import { Content, Color } from '../components/ThemeProvider/ThemeStyle';
import SideBar from '../components/Header/SideBar/SideBar';
import CheckTabNumbers from '../components/ChekTabNumbers/ChekTabNumbers';
import BadGateway from '../components/BadGateway/BadGateway';
import paths from '../_helpers/paths';
import { history } from '../_helpers/history';
import { kezekPassportModule } from '../pages/KezekPassportPage/KezekPassportDucks';
import TokenRefresher from '../components/TokenRefresher/TokenRefresher';

function Root({ checkLogin, invalidPassport, ...props }) {
  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    if (invalidPassport && props.location.pathname !== paths.kezekPassport) {
      history.push(paths.kezekPassport);
    }
  }, [invalidPassport, props.location.pathname]);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      checkLogin();
    }
  }, [checkLogin]);

  return (
    <>
      <NotificationContainer />
      <BadGateway>
        <div className="flex">
          <CheckTabNumbers>
            <Route
              exact
              path={sidebarPaths}
              component={() =>
                props.user && (
                  <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
                )
              }
            />

            <div style={{ width: 'calc(100% - 50px)' }}>
              <div style={{ height: Color.headerHeight }}>
                <Route
                  exact
                  path={headerPaths}
                  component={() => (
                    <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
                  )}
                />
              </div>
              <Content>
                <TokenRefresher/>
                <Switch>
                  <ProtectedRoute exact {...props} {...routerProps.homePage} />
                  <ProtectedRoute exact {...props} {...routerProps.loginPage} />
                  <ProtectedRoute exact {...props} {...routerProps.statementPage} />
                  <ProtectedRoute exact {...props} {...routerProps.statementViewPage} />
                  <ProtectedRoute exact {...props} {...routerProps.journalPage} />
                  <ProtectedRoute exact {...props} {...routerProps.monitoringTablePage} />
                  <ProtectedRoute
                    exact
                    {...props}
                    {...routerProps.monitoringChartsPage}
                  />
                  <ProtectedRoute exact {...props} {...routerProps.handbookPage} />
                  <ProtectedRoute
                    exact
                    {...props}
                    {...routerProps.serviceProvidersPage}
                  />
                  <ProtectedRoute
                    exact
                    {...props}
                    {...routerProps.serviceProviderViewPage}
                  />
                  <ProtectedRoute exact {...props} {...routerProps.serviceProviderPage} />
                  <ProtectedRoute exact {...props} {...routerProps.administrationPage} />
                  <ProtectedRoute exact {...props} {...routerProps.instructionPage} />
                  <ProtectedRoute exact {...props} {...routerProps.archivePage} />
                  <Route exact {...props} {...routerProps.contactSupportPage} />
                  <ProtectedRoute exact {...props} {...routerProps.manualPage} />
                  <Route exact {...props} {...routerProps.faqPage} />
                  <ProtectedRoute exact {...props} {...routerProps.myTaskPage} />
                  <ProtectedRoute exact {...props} {...routerProps.taskPage} />
                  <ProtectedRoute exact {...props} {...routerProps.queueTab1Page} />
                  <ProtectedRoute exact {...props} {...routerProps.queueTab2Page} />
                  <ProtectedRoute exact {...props} {...routerProps.queueByName} />

                  {/* KEZEK */}
                  <ProtectedRoute exact {...props} {...routerProps.kezekBulletinTable} />
                  <ProtectedRoute exact {...props} {...routerProps.kezekBulletin} />
                  <ProtectedRoute exact {...props} {...routerProps.kezekDirectionTable} />
                  <ProtectedRoute exact {...props} {...routerProps.kezekDirection} />
                  {/*<ProtectedRoute exact {...props} {...routerProps.kezekContingent} />*/}
                  <ProtectedRoute exact {...props} {...routerProps.kezekPassport} />
                  <ProtectedRoute exact {...props} {...routerProps.kezekRequest} />
                  <ProtectedRoute exact {...props} {...routerProps.kezekStopList} />
                  <ProtectedRoute exact {...props} {...routerProps.kezekArchive} />

                  {/* groups */}
                  <ProtectedRoute exact {...props} {...routerProps.groupsTabelPage} />
                  <ProtectedRoute exact {...props} {...routerProps.groupPage} />
                  <ProtectedRoute exact {...props} {...routerProps.groupListPage} />

                  {/* class */}
                  <ProtectedRoute exact {...props} {...routerProps.classPage} />
                  <ProtectedRoute exact {...props} {...routerProps.classAddPage} />
                  <ProtectedRoute exact {...props} {...routerProps.classItemPage} />

                  {/* contingent */}
                  <ProtectedRoute exact {...props} {...routerProps.contingentCandidatePage} />
                  <ProtectedRoute exact {...props} {...routerProps.contingentEnrolledPage} />
                  <ProtectedRoute exact {...props} {...routerProps.contingentExitedPage} />
                  <ProtectedRoute exact {...props} {...routerProps.contingentItemPage} />

                  {/* Colleagues */}
                  <ProtectedRoute exact {...props} {...routerProps.employessPage} />
                  <ProtectedRoute exact {...props} {...routerProps.dismissedPage} />
                  <ProtectedRoute exact {...props} {...routerProps.colleaguesPassportPage} />
                  <ProtectedRoute exact {...props} {...routerProps.colleaguesPassportUpdatePage} />
                  <ProtectedRoute exact {...props} {...routerProps.colleaguesReportPage} />
                  {/*<ProtectedRoute exact {...props} {...routerProps.contingentReportDDOPage} />*/}

                   {/* Contingent school */}
                  <ProtectedRoute exact {...props} {...routerProps.retiredPage} />
                  <ProtectedRoute exact {...props} {...routerProps.pupilsPage} />
                  <ProtectedRoute exact {...props} {...routerProps.contingentSchoolPage} />
                  <ProtectedRoute exact {...props} {...routerProps.contingentSchoolItemPage} />
                  <ProtectedRoute exact {...props} {...routerProps.contingentReportSchoolPage} />
                  <ProtectedRoute exact {...props} {...routerProps.contingentReportFoodPage} />

                  {/* Contingent section */}
                  <ProtectedRoute exact {...props} {...routerProps.studentPage} />
                  <ProtectedRoute exact {...props} {...routerProps.departPage} />
                  <ProtectedRoute exact {...props} {...routerProps.contingentSectionPage} />
                  <ProtectedRoute exact {...props} {...routerProps.contingentSectionItemPage} />
                  <ProtectedRoute exact {...props} {...routerProps.contingentReportSectionPage} />

                  <Route {...props} {...routerProps.resetPassPage} />
                  <Route {...props} {...routerProps.changePass} />
                  <Route component={Page404} />
                </Switch>
              </Content>
            </div>
          </CheckTabNumbers>
        </div>
      </BadGateway>
    </>
  );
}

export default connect(
  state => ({
    user: state[loginModule].user,
    userPermissions: state[loginModule].permissions,
    loading: state[loginModule].loading,
    invalidPassport: state[kezekPassportModule].invalidPassport
  }),
  { checkLogin }
)(withRouter(Root));
